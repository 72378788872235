<template>
  <div class="browse">
    <h2>What would you like to do ?</h2>
    <div class="links">
      <template v-for="(link, $index) in browseLinks">
        <div :key="`links_${$index}`">
          <router-link :class="{ 'access-denied' : !link.hasAccess }" :event="link.hasAccess ? 'click' : ''" :to="link.to">
            <fa v-if="link.icon" :icon="link.icon" size="2x" />
            <span>{{ link.title }}</span>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Browse",
  metaInfo: () => ({
    title: "EnglishScore - CMS : Browse",
  }),
  computed: {
    ...mapGetters({
      browseLinks: "browse/links",
    }),
  },
};
</script>
<style lang="scss" scoped>
.browse {
  & h2 {
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.25em;
    @include media-breakpoint-up(md) {
      font-size: 1.65em;
    }
  }
  & .links {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 90%;
    }
    @include media-breakpoint-up(lg) {
      width: 80%;
    }
    & div {
      width: 50%;
      height: 150px;
      transition: 0.2s;
      margin-top: 8px;
      margin-bottom: 8px;
      @include media-breakpoint-up(md) {
        width: 33.33%;
        height: 150px;
      }
      @include media-breakpoint-up(lg) {
        width: 25%;
        height: 150px;
      }
      & a {
        color: rgba(white, 0.8);
        border-radius: 4px;
        margin-right: 8px;
        margin-left: 8px;
        display: flex;
        flex: 1;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: $dark-grey;
        text-decoration: none;
        font-family: "BritishCouncilSansW15-Headline", sans-serif;
        font-size: 0.75em;
        padding: 1em;
        flex-direction: column;
        will-change: auto;
        @include media-breakpoint-up(md) {
          font-size: 1.2em;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1em;
        }
        &:hover {
          color: white;
        }
        & span {
          display: inline-block;
          margin-top: 1em;
          text-align: center;
        }
        &.access-denied {
          background-color: lightgray;
          &:hover {
            color: rgba(white, 0.3);
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>